import React from 'react'
import './Home.css'

function HomeHeader({ title, isHeader1 }) {
  return (
    <div className={`home-header-${isHeader1 ? '1' : '2'}`}>
      <h4>{title}</h4>
    </div>
  )
}

export default HomeHeader
