import React, { useState } from 'react'
import './Login.css'
import { FaEnvelope, FaPhone, FaBuilding } from 'react-icons/fa'
import Footer from '../../components/Footer/Footer'
import 'leaflet/dist/leaflet.css'
import { collection, addDoc } from 'firebase/firestore'
import { db } from './firebase'

const Map = () => {
  return (
    <div className="map-container">
      <div className="map-wrapper">
        {/* İframe kodu */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d752.886023044694!2d29.09222658166857!3d40.99146292883939!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac7e23be063c9%3A0x351ef6799eaabaa5!2s%C4%B0ler%20Otomotiv!5e0!3m2!1str!2str!4v1710517904684!5m2!1str!2str"
          width="600"
          height="250"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title="Google Maps"
        ></iframe>
      </div>

      <div className="contact-info">
        <div>
          <FaEnvelope style={{ fontSize: '30px' }} />
          <div className="info">erdal@ilerotomotiv.com.tr</div>
          <div className="info">info@ilerotomotiv.com.tr</div>
        </div>
        <div>
          <FaPhone style={{ fontSize: '30px' }} />
          <div className="info">543 780 3504</div>
        </div>
        <div>
          <FaBuilding style={{ fontSize: '30px' }} />
          <div className="info">
            Barbaros Mah.Barbaros Cad. No:52/2 Ataşehir – İstanbul
          </div>
        </div>
      </div>
    </div>
  )
}

const Login = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await addDoc(collection(db, 'contactdata'), {
        name,
        email,
        message,
      })
      console.log('Mesaj başarıyla gönderildi.')
      setName('')
      setEmail('')
      setMessage('')
    } catch (error) {
      console.error('Error sending message:', error)
    }
  }

  return (
    <>
      <div className="contacts">
        <div className="App">
          <h1 className="heading-map">BİZE ULAŞIN</h1>
          <div className="content-container">
            <Map />
            <div className="form-container">
              <h1 className="heading-form">İLETİŞİM FORMU</h1>
              <div className="inputs">
                <form onSubmit={handleSubmit} className="contact-form">
                  <label htmlFor="name">Adınız</label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Adınız"
                  />

                  <label htmlFor="email">E-posta Adresiniz</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-posta Adresiniz"
                  />

                  <label htmlFor="message">Mesajınız</label>
                  <textarea
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Mesajınız"
                  />

                  <button type="submit">Gönder</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Login
