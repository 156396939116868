import React from 'react'
import './Home.css'

function HomeCompany({ name, logoSrc, link }) {
  return (
    <div className="post">
      <a href={link} target="_blank" rel="noopener noreferrer">
        <img src={logoSrc} alt={name} />
        <div className="post-t">
          <h4>{name}</h4>
        </div>
      </a>
    </div>
  )
}

function HomeCompaniesSection({ companyList, backgroundImage }) {
  return (
    <section className="home-section-5">
      <img
        src={backgroundImage}
        alt="Resim Açıklaması"
        className="home-image-5"
      />
      <div className="home-text-5">
        {companyList.map((company, index) => (
          <HomeCompany
            key={index}
            name={company.name}
            logoSrc={company.logoSrc}
            link={company.link}
          />
        ))}
      </div>
    </section>
  )
}

export default HomeCompaniesSection
