import React, { useEffect } from 'react'
import { FiTrendingUp } from 'react-icons/fi'
import { IoSettingsOutline } from 'react-icons/io5'
import { FaRegHandshake } from 'react-icons/fa'
import { gsap } from 'gsap'
import './about.css'
import Footer from '../../components/Footer/Footer'

const About = () => {
  useEffect(() => {
    const screenWidth = window.innerWidth

    // Ekran genişliği 1000px'in üzerindeyse animasyonu başlat
    if (screenWidth > 1000) {
      gsap.from('.hakkimizda-content', {
        opacity: 0,
        y: 100,
        duration: 1,
        ease: 'power3.out',
        scrollTrigger: {
          trigger: '.hakkimizda-content',
          start: 'center bottom',
        },
      })
    }
  }, [])
  return (
    <>
      <section className="hakkimizda-page">
        <div className="hakkimizda-content">
          <div className="hakkimizda-section-left">
            <div className="hakkimizda-header-1">
              <h3 className="hakkimizda-title-1">Hakkımızda</h3>
              <p className="hakkimizda-text">
                Şirket ortakları olarak sektörde yaşadığımız 34 yıllık
                tecrübemiz bulunmaktadır. Yedek parça piyasasında çıraklıktan
                tepe yönetime kadar çalışmalarımız oldu. Taksim yedek parça
                piyasasından Doğuş Otomotiv deneyimine kadar çalışmalarımız
                oldu. Bu tecrübeler bize piyasada bulunan eksikler için bir ışık
                tuttu ve bu şirketi 2023 yılında kurmaya karar verdik. Şirket
                açılışında yeni olabiliriz fakat piyasanın içinde olduğumuz için
                tecrübemiz artmıştır. <br /> <br /> "İLER OTOMOTİV”; Otomotiv
                yedek parça listelerinin analizi ve tanımlanması, listelerin
                revizyonu, Logosuz Orijinal / Eşdeğer tanımlarının kontrolü,
                menşei, sertifikasyonu, sertifikaların Id numaraları, geçerlilik
                durum ve tarihinin kontrolü, parça kalitelerinin belirlenmesi,
                eşdeğer parça fiyatlarının güncel distribütör veya orijinal
                yedek parça fiyatları ile karşılaştırılması, ıskonto analizi,
                eşdeğer parça kodlarının distribütör firma OEM katalog kodları
                ile eşleştirilmesi, parça kullanım/talep durumu analizleri,
                mevcut listelerin performanslarının artırımı / iyileştirilmesi,
                süreç analizleri, konularında danışmanlık hizmeti veren bir
                firmadır.
              </p>
            </div>
          </div>

          <div className="hakkimizda-section-right">
            <div className="hakkimizda-icons">
              <div className="hakkimizda-icon">
                <FiTrendingUp className="icon-1" />
              </div>
              <div className="hakkimizda-icon">
                <FaRegHandshake className="icon-2" />
              </div>
              <div className="hakkimizda-icon">
                <IoSettingsOutline className="icon-3" />
              </div>
            </div>
          </div>

          <div className="hakkimizda-section-right">
            <div className="hakkimizda-header-2">
              <h5 className="hakkimizda-title-2">Şirket Sloganı</h5>
              <p className="hakkimizda-text">
                Yedek parça konusunda aradığınız destek yanınızda Yedek parça da
                çözüm ortağınız Daimî destek ortağınız Sonuçlara ihtiyacınız
                olduğunda yedek parça destek yanınızda Otomotiv yedek parçada
                vazgeçilmez partner İler Otomotiv ile daima ileri.
              </p>
            </div>

            <div className="hakkimizda-header-2">
              <h5 className="hakkimizda-title-2">Misyonumuz</h5>
              <p className="hakkimizda-text">
                Otomotiv yedek parça sektöründe danışmanlık ve yedek parça
                ticaretini en iyi şekilde yapmaya çalışıyoruz. Sigorta, katalog,
                araç filo, ihale portalları için gerekli bilgi ve deneyime sahip
                olup, iş ortaklarımıza hizmet destek vermeye devam edeceğiz.
              </p>
            </div>

            <div className="hakkimizda-header-2">
              <h5 className="hakkimizda-title-2">Vizyonumuz</h5>
              <p className="hakkimizda-text">
                Otomotiv yedek parça piyasasında tanınan, güvenilen, danışılan
                firmaların başında gelmektir.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default About
