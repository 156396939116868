import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyCBLvSgL-N-isZ5-1wkMItQwOZqDsv9Wz8',
  authDomain: 'ileriletisim-d246c.firebaseapp.com',
  projectId: 'ileriletisim-d246c',
  storageBucket: 'ileriletisim-d246c.appspot.com',
  messagingSenderId: '771512761068',
  appId: '1:771512761068:web:69a7f74c7f5734b83a43c9',
  measurementId: 'G-MMG034CSKV',
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const analytics = getAnalytics(app)
