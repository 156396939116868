import React, { useRef, useEffect } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './Products.css'
import Footer from '../../components/Footer/Footer'
import '../Home/Home.css'
import HomeHeader from '../Home/HomeHeader'
import HomeSection from '../Home/HomeSection'
import HomeTextSection from '../Home/HomeTextSection'
import HomeCompaniesSection from '../Home/HomeCompaniesSection'
const companiesList = [
  {
    name: 'Dat Katalog',
    logoSrc: '/Dat_Logo.png',
    link: 'https://www.dat.de/',
  },
  {
    name: 'Digilera',
    logoSrc: '/Digilera_Logo.jpg',
    link: 'https://digilera.com/',
  },
  {
    name: 'EMAA Sigorta',
    logoSrc: '/Emaasigorta_Logo.jpeg',
    link: 'https://emaasigorta.com.tr/',
  },
  {
    name: 'Ketsoft Yazılım Teknolojileri',
    logoSrc: '/Ketsoft_Logo.jpg',
    link: 'https://ketsoft.com/ketsoft/website/(S(oyusueureootwxhephvcksc2))/default.aspx',
  },
  {
    name: 'Doğa Sigorta',
    logoSrc: '/DogaS_Logo.webp',
    link: 'https://www.dogasigorta.com/',
  },
  {
    name: 'DeGA',
    logoSrc: '/Dega_Logo.jpeg',
    link: 'https://www.de-ga.com.tr/',
  },
  {
    name: 'İkimiz Otomotiv',
    logoSrc: '/İkimiz_Logo.png',
    link: 'http://www.ikimizoto.com.tr/',
  },
  {
    name: 'Fleet Assist',
    logoSrc: '/Fleetassist_Logo.jpeg',
    link: 'https://www.fleetassist.com.tr/',
  },
  {
    name: 'RS Servis',
    logoSrc: '/Rs_Logo.png',
    link: 'https://www.rsservis.com.tr/',
  },
  {
    name: 'Garantili Oto Servis',
    logoSrc: '/Garantilioto_Logo.png',
    link: 'https://www.garantiliotoservis.com/',
  },
]

const AnimatedSection = ({ children, isReverse }) => {
  const sectionRef = useRef(null)

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: 'top 80%',
        toggleActions: 'play none none reverse',
      },
    })

    if (isReverse) {
      tl.from(sectionRef.current, { opacity: 0, y: 50, duration: 1 })
    } else {
      tl.from(sectionRef.current, { opacity: 0, y: -50, duration: 1 })
    }
  }, [isReverse])

  return <div ref={sectionRef}>{children}</div>
}

const Products = () => {
  return (
    <>
      <div className="container">
        <AnimatedSection isReverse={false}>
          <div className="container-header">
            <h1 className="container-header">Hizmetlerimiz</h1>
          </div>
          <HomeHeader
            title="Otomotiv Üreticileri / Distribütörleri"
            isHeader1={true}
          />
        </AnimatedSection>
        <AnimatedSection isReverse={false}>
          <HomeSection
            text={
              <div>
                <span>1. Pazar Analizi:</span> Otomotiv sektöründe pazar
                analizi, mevcut pazar durumunu değerlendirme, gelecekteki
                trendleri öngörme ve potansiyel fırsatları belirleme sürecidir.
                Firma olarak, pazar analizi yaparak tüketici taleplerini,
                sektöre olan genel etkileri ve müşteri tercihlerini inceleyerek,
                şekillendiriyoruz.
                <br /> <br />
                <span>2. Rakip Analizi ve Benchmark Çalışmaları:</span> Rakip
                analizi, firmaların faaliyetlerini inceleyerek güçlü ve zayıf
                yönlerini belirleme sürecidir. Aynı zamanda benchmark
                çalışmaları ile kendi performansımızı sektör standartlarına göre
                değerlendiriyoruz. Bu analizler, rekabet avantajları elde etmek,
                yenilikçi olmak ve müşteri beklentilerini aşmak için stratejik
                kararlar almamıza yardımcı oluyor. <br /> <br />
                <span>3. Orijinal Fiyat Karşılaştırmaları:</span> Otomotiv
                sektöründe üretilen ürünlerin orijinal fiyatlarını belirleyerek,
                fiyatlandırma stratejilerimizi optimize etmek amacıyla
                karşılaştırmalar yapıyoruz. Bu sayede müşterilere uygun fiyatlar
                sunarken aynı zamanda işimizin sürdürülebilirliğini sağlıyoruz.{' '}
                <br />
                <br />
                <span>4. Kampanya Yönetimi, Önerileri:</span> Firmamız,
                sektördeki en güncel pazarlama stratejilerini ve kampanya
                yönetimi tekniklerini kullanarak müşterilere cazip teklifler
                sunmayı hedefler. Ayrıca, kampanya performansını değerlendirip
                stratejilerimizi sürekli optimize ederiz. <br />
                <br />
                <span>5. Excess and Obsolete Stok Değerlemesi:</span> Firma
                olarak, stoklarımızı düzenli olarak değerlendiriyor ve gereksiz
                veya güncellenmiş stokları belirleyip etkili bir şekilde
                yönetiyoruz. Bu sayede maliyetleri minimize ederken müşteri
                memnuniyetini de artırıyoruz. <br />
                <br />
                <span>6. Diğer Danışmanlıklar:</span> Firmanıza özel danışmanlık
                hizmetleri sunmaktayız. Bu, iş süreçlerini iyileştirmek,
                verimliliği artırmak ve sürdürülebilir büyümeyi desteklemek
                amacıyla yapılan özelleştirilmiş danışmanlık hizmetlerini
                içerir. İhtiyaçlarınıza uygun çözümler sunarak sektördeki
                konumumuzu korumayı hedefliyoruz.
              </div>
            }
            image="/analiz-2.jpg"
            isReverse={false}
          />
        </AnimatedSection>

        <AnimatedSection isReverse={false}>
          <HomeHeader
            title="Parça, Komponent, Ekipman Üretici/ Distribütörleri"
            isHeader1={false}
          />
        </AnimatedSection>
        <AnimatedSection isReverse={true}>
          <HomeTextSection
            text={
              <div>
                <span>1. Rekabet Analizi:</span> Parça, komponent ve ekipman
                üreticisi olarak rekabet analizi yapmak, sektördeki diğer
                firmaların güçlü ve zayıf yönlerini anlamak demektir. Firmamız,
                rekabet avantajları elde etmek ve pazar payını artırmak için
                rakiplerimizin faaliyetlerini sürekli olarak değerlendirir. Bu
                analizler, ürün ve hizmet stratejilerimizi geliştirmemize ve
                müşteri memnuniyetini artırmamıza yardımcı olur.
                <br />
                <br />
                <span>2. Piyasa Bilgilendirme:</span> Firmamız, müşterilere en
                güncel bilgileri sağlamak ve ihtiyaçlarına uygun çözümler sunmak
                için piyasa araştırmalarını düzenli olarak gerçekleştirir. Bu
                sayede müşterilerimizle güçlü bir ilişki kurar ve sektördeki
                değişikliklere hızlı bir şekilde adapte olabiliriz. <br />
                <br />
                <span>3. İnternet Tabanlı Katalog Satış Portalı:</span>{' '}
                Müşterilerimize daha etkili bir şekilde ulaşmak ve ürünlerimizi
                tanıtmak amacıyla internet tabanlı katalog satış portalı
                kullanıyoruz. Bu portal, müşterilere ürünleri detaylı bir
                şekilde gösterme, fiyatlandırma ve online sipariş verme imkanı
                sunar. Böylece müşterilere daha hızlı ve pratik bir alışveriş
                deneyimi yaşatırız.
                <br />
                <br />
                <span>4. Servis Yazılımı:</span> Firmamız, müşterilerimize özel
                servis yazılımları sunar. Bu yazılımlar, bakım planlaması, arıza
                takibi ve verimlilik analizi gibi konularda müşterilere kolaylık
                sağlar. Aynı zamanda, servis süreçlerini optimize ederek müşteri
                memnuniyetini artırmayı hedefleriz. <br />
                <br />
                <span>5. Stok Yönetimi:</span> Firmamız, stok süreçlerini etkin
                bir şekilde yöneterek maliyetleri minimize eder ve müşterilere
                zamanında teslimat garantisi sağlar. Bu sayede, müşteri
                taleplerini karşılamak için doğru miktarlarda stok bulundururuz.{' '}
                <br />
                <br />
                <span>6. Süreç Analizi ve İyileştirmeleri:</span> İş süreçlerini
                analiz ederek verimliliği artırmak ve maliyetleri düşürmek,
                firmamızın stratejik hedeflerinden biridir. Süreç analizi ve
                iyileştirmeleri ile iş akışlarını optimize eder ve müşteri
                memnuniyetini artırmak için sürekli çaba sarf ederiz. <br />
                <br />
                <span>7. Diğer Danışmanlıklar:</span> Firmanıza özel ihtiyaçlara
                yönelik olarak çeşitli danışmanlık hizmetleri sunmaktayız. Bu,
                iş süreçlerini iyileştirmek, teknolojik altyapıyı güçlendirmek
                ve rekabet avantajları elde etmek amacıyla yapılan
                özelleştirilmiş danışmanlık hizmetlerini içerir. İhtiyaçlarınıza
                uygun çözümler sunarak sektördeki lider konumumuzu sürdürmeyi
                hedefliyoruz.
              </div>
            }
            image="/analiz-3.jpg"
            isReverse={true}
          />
        </AnimatedSection>

        <AnimatedSection isReverse={false}>
          <HomeHeader
            title="Sigorta ve Filo Kiralama Şirketleri"
            isHeader1={true}
          />
        </AnimatedSection>
        <AnimatedSection isReverse={false}>
          <HomeSection
            text={
              <div>
                <span>1. Orijinal / Eşdeğer Parça Analizleri:</span> Sigorta ve
                filo kiralama sektöründe, araçların onarımları için kullanılan
                parçaların orijinal ve eşdeğer parçalar arasındaki farkları
                belirlemek kritiktir. Firma olarak, araçların güvenliği ve
                performansı gözetilerek orijinal ve eşdeğer parçaların analizini
                yaparız. Bu sayede müşterilere uygun maliyetli ve güvenilir
                onarımlar için önerilerde bulunuruz. <br /> <br />
                <span>2. Araç Teknik Bilgileri:</span> Firma olarak, araçların
                teknik özelliklerini detaylı bir şekilde inceleyerek,
                müşterilerimize en uygun olanları seçmelerinde yardımcı oluruz.
                <br /> <br />
                <span>3. Maliyet Yönetimi / Analizleri:</span> Firma olarak,
                araç bakımı, onarımı ve sigorta maliyetleri gibi konularda
                detaylı analizler yaparız. Bu analizler, maliyetleri minimize
                etmek ve müşterilere uygun fiyatlı hizmetler sunmak amacıyla
                yapılmaktadır. <br /> <br />
                <span> 4. Piyasa Analizleri:</span> Firma olarak, sigorta ve
                filo kiralama sektöründeki güncel gelişmeleri takip eder ve
                müşterilere stratejik öneriler sunarız. Bu analizler, sektördeki
                rekabeti ve müşteri taleplerini anlamamıza yardımcı olur. <br />{' '}
                <br />{' '}
                <span>
                  5. Hasar Parçaları, Periyodik Bakım Karşılaştırmaları:
                </span>{' '}
                Firma olarak, araçların hasarlı parçalarını ve periyodik bakım
                ihtiyaçlarını analiz ederek etkin çözümler sunarız. Bu,
                müşterilerin filo yönetimini daha verimli hale getirmelerine
                yardımcı olur.
                <br /> <br /> <span>
                  {' '}
                  6. Diğer Danışmanlık Hizmetleri:
                </span>{' '}
                Firmanıza özel ihtiyaçlara yönelik olarak çeşitli danışmanlık
                hizmetleri sunmaktayız. Bu, iş süreçlerini iyileştirmek,
                operasyonel verimliliği artırmak amacıyla yapılan
                özelleştirilmiş danışmanlık hizmetlerini içerir. İhtiyaçlarınıza
                uygun çözümler sunarak sektördeki lider konumumuzu sürdürmeyi
                hedefliyoruz.
              </div>
            }
            image="/kiralama-2.jpg"
            isReverse={false}
          />
        </AnimatedSection>

        <AnimatedSection isReverse={false}>
          <HomeHeader title="Başlıca faaliyetlerimiz" isHeader1={false} />
        </AnimatedSection>
        <AnimatedSection isReverse={true}>
          <HomeTextSection
            text={
              <div>
                <span>1. Pazar Araştırmaları:</span> Firma olarak, pazar
                araştırmaları yaparak, müşteri taleplerini, sektördeki trendleri
                ve potansiyel fırsatları değerlendirir. Bu analizler, müşteri
                ihtiyaçlarına uygun ürün ve hizmetler geliştirmenize, rekabet
                avantajı elde etmenize ve stratejik kararlar almanıza yardımcı
                olur. <br />
                <br />
                <span>2.Veri ve Bilgi Sağlayıcılığı:</span> Otomotiv
                distribütörleri, parça üreticileri ve sigorta şirketlerine
                yönelik olarak sektördeki güncel veri ve bilgileri sağlayarak,
                müşterilerin karar alma süreçlerini desteklersiniz. Bu,
                müşterilerin doğru ve güvenilir bilgilere erişimini sağlayarak
                iş süreçlerini daha etkili yönetmelerine yardımcı olur. <br />
                <br />
                <span>3. Danışmanlık Hizmetleri:</span> Otomotiv parça sektörüne
                yönelik olarak danışmanlık hizmetleri sunarak, müşterilere
                stratejik yönlendirmelerde bulunursunuz. Bu, iş süreçlerini
                iyileştirmek, maliyetleri optimize etmek, yeni pazarlara giriş
                stratejileri belirlemek gibi konularda müşterilere rehberlik
                etmenizi içerir.
                <br />
                <br />
                <span>4. Müşteri Taleplerine Uygun Çözümler:</span> Otomotiv
                distribütörleri, parça üreticileri ve sigorta şirketlerinin
                taleplerini anlayarak, özelleştirilmiş çözümler sunarsınız. Bu,
                müşterilerinizin spesifik ihtiyaçlarına uygun olarak ürün ve
                hizmet portföyünüzü şekillendirme yeteneğinizi yansıtır. <br />
                <br />
                <span>5. Sektörel Bilgi ve Uzmanlık:</span> Otomotiv parça
                sektöründeki güncel gelişmeleri takip ederek, müşterilere
                sektörel bilgi ve uzmanlık sunmaktayız. Buda, müşterilerimizin
                rekabet avantajı elde etmelerine ve hızla değişen pazar
                koşullarına uyum sağlamalarına yardımcı olur. Başlıca
                faaliyetlerimiz, otomotiv parça sektöründe kapsamlı hizmetler
                sunarak, sektördeki konumunuzu güçlendirmenize olanak tanır.
              </div>
            }
            image="/SparePart-1.jpg"
            isReverse={true}
          />
        </AnimatedSection>
      </div>

      <AnimatedSection isReverse={false}>
        <HomeHeader title="Hizmet Verilen Firmalar" isHeader1={true} />
      </AnimatedSection>
      <AnimatedSection isReverse={false}>
        <HomeCompaniesSection
          companyList={companiesList}
          backgroundImage="/transporter.jpg"
        />
      </AnimatedSection>
      <Footer />
    </>
  )
}

export default Products
