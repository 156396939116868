import React, { useState, useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Footer from '../../components/Footer/Footer'
import './Home.css'
import HeaderSection from './HeaderSection'
import HomeHeader from './HomeHeader'
import HomeSection from './HomeSection'
import HomeTextSection from './HomeTextSection'
import HomeCompaniesSection from './HomeCompaniesSection'
import { GoTriangleLeft, GoTriangleRight } from 'react-icons/go'

gsap.registerPlugin(ScrollTrigger)

const companiesList = [
  {
    name: 'Dat Katalog',
    logoSrc: '/Dat_Logo.png',
    link: 'https://www.dat.de/',
  },
  {
    name: 'Digilera',
    logoSrc: '/Digilera_Logo.jpg',
    link: 'https://digilera.com/',
  },
  {
    name: 'EMAA Sigorta',
    logoSrc: '/Emaasigorta_Logo.jpeg',
    link: 'https://emaasigorta.com.tr/',
  },
  {
    name: 'Ketsoft Yazılım Teknolojileri',
    logoSrc: '/Ketsoft_Logo.jpg',
    link: 'https://ketsoft.com/ketsoft/website/(S(oyusueureootwxhephvcksc2))/default.aspx',
  },
  {
    name: 'Doğa Sigorta',
    logoSrc: '/DogaS_Logo.webp',
    link: 'https://www.dogasigorta.com/',
  },
  {
    name: 'DeGA',
    logoSrc: '/Dega_Logo.jpeg',
    link: 'https://www.de-ga.com.tr/',
  },
  {
    name: 'İkimiz Otomotiv',
    logoSrc: '/İkimiz_Logo.png',
    link: 'http://www.ikimizoto.com.tr/',
  },
  {
    name: 'Fleet Assist',
    logoSrc: '/Fleetassist_Logo.jpeg',
    link: 'https://www.fleetassist.com.tr/',
  },
  {
    name: 'RS Servis',
    logoSrc: '/Rs_Logo.png',
    link: 'https://www.rsservis.com.tr/',
  },
  {
    name: 'Garantili Oto Servis',
    logoSrc: '/Garantilioto_Logo.png',
    link: 'https://www.garantiliotoservis.com/',
  },
]

const AnimatedSection = ({ children, isReverse }) => {
  const sectionRef = useRef(null)

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: 'top 80%',
        toggleActions: 'play none none reverse',
      },
    })

    if (isReverse) {
      tl.from(sectionRef.current, { opacity: 0, y: 50, duration: 1 })
    } else {
      tl.from(sectionRef.current, { opacity: 0, y: -50, duration: 1 })
    }
  }, [isReverse])

  return <div ref={sectionRef}>{children}</div>
}

const Home = () => {
  const [text, setText] = useState(0)

  function after() {
    setText(text === HeaderSection.length - 1 ? 0 : text + 1)
  }

  function before() {
    setText(text === 0 ? HeaderSection.length - 1 : text - 1)
  }

  return (
    <>
      <div className="container">
        <div className="spare-container">
          {HeaderSection.map(
            (item, index) =>
              text === index && (
                <div className="overlay-text" key={item.name}>
                  {item.name}
                </div>
              )
          )}
        </div>

        <div className="image-container">
          {HeaderSection.map(
            (item, index) =>
              text === index && (
                <div key={item.id}>
                  <img
                    src={item.url}
                    alt={item.name}
                    className="header-image"
                  />
                  <div className="button-container">
                    <button onClick={before} className="buton">
                      <GoTriangleLeft size={50} />
                    </button>
                    <button onClick={after} className="buton">
                      <GoTriangleRight size={50} />
                    </button>
                  </div>
                </div>
              )
          )}
        </div>
      </div>

      <div className="container">
        <AnimatedSection>
          <HomeHeader
            title="Otomotiv Üreticileri / Distribütörleri"
            isHeader1={true}
          />
          <HomeSection
            text={
              <div>
                • Pazar Analizi <br />
                • Rakip Analizi ve Benchmark Çalışmaları <br />
                • Orijinal Fiyat Karşılaştırmaları <br />
                • Kampanya Yönetimi, Önerileri <br />
                • Excess and Obsolite Stok Değerlemesi <br />• Diğer
                Danışmanlıklar
              </div>
            }
            image="/analiz-1.jpg"
            isReverse={false}
          />
        </AnimatedSection>
        <AnimatedSection>
          <HomeHeader
            title="Parça, Komponent, Ekipman Üretici/ Distribütörleri"
            isHeader1={false}
          />
          <HomeTextSection
            text={
              <div>
                • Rekabet Analizi <br />
                • Piyasa Bilgilendirme <br />
                • İnternet Tabanlı Katalog Satış Portalı <br />
                • Servis Yazılımı <br />
                • Stok Yönetimi <br />
                • Süreç Analizi ve İyileştirmeleri <br />• Diğer Danışmanlıklar
              </div>
            }
            image="/danismanlik.jpg"
            isReverse={true}
          />
        </AnimatedSection>
        <AnimatedSection>
          <HomeHeader
            title="Sigorta ve Filo Kiralama Şirketleri"
            isHeader1={true}
          />
          <HomeSection
            text={
              <div>
                • Orijinal / Eşdeğer Parça Analizleri <br />
                • Araç Teknik Bilgileri <br />
                • Maliyet Yönetimi / Analizleri <br />
                • Piyasa Analizleri <br />
                • Hasar Parçaları, Periyodik Bakım Karşılaştırmaları <br />•
                Diğer Danışmanlık Hizmetleri
              </div>
            }
            image="/kiralama.jpg"
            isReverse={false}
          />
        </AnimatedSection>
        <AnimatedSection>
          <HomeHeader title="Başlıca faaliyetlerimiz" isHeader1={false} />
          <HomeTextSection
            text={
              <div>
                Otomotiv parçaları konusunda talep eden otomotiv
                distribütörleri, parça üreticileri ve sigorta şirketlerine pazar
                araştırmaları, veri ve bilgi sağlayıcılığı ve çeşitli konularda
                danışmanlık hizmetleri vermektedir.
              </div>
            }
            image="/engine.jpg"
            isReverse={true}
          />
        </AnimatedSection>
      </div>
      <AnimatedSection>
        <HomeHeader title="Hizmet Verilen Firmalar" isHeader1={true} />

        <HomeCompaniesSection
          companyList={companiesList}
          backgroundImage="/Viper.jpg"
        />
      </AnimatedSection>

      <Footer />
    </>
  )
}
export default Home
