import { Outlet } from 'react-router-dom'
import StyledNavbar from '../components/Navbar/StyledNavbar'
const SharedLayout = () => {
  return (
    <>
      <StyledNavbar />
      <Outlet />
      {/* BAK <Footer /> */}
    </>
  )
}
export default SharedLayout
