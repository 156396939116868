import React from 'react'
import './Home.css'

function HomeSection({ text, image, isReverse }) {
  return (
    <div className={`home-section-${isReverse ? '2' : '1'}`}>
      <img
        src={image}
        alt="Resim Açıklaması"
        className={`home-image-${isReverse ? '2' : '1'}`}
      />
      <section className={`home-text-${isReverse ? '2' : '1'}`}>
        <p>{text}</p>
      </section>
    </div>
  )
}

export default HomeSection
