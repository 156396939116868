const HeaderSection = [
  {
    id: 1,
    name: 'Yedek parça konusunda güvenilir destek arıyorsanız, İler Otomotiv yanınızda! Daimî destek ortağınız olarak, sonuçlara ihtiyaç duyduğunuz her an, yedek parça desteğimizle hızlı çözümler sunuyoruz. İler Otomotiv ile otomotiv yedek parça sektöründe ileriye bir adım atın.',
    url: '/Car.gif',
  },
  {
    id: 2,
    name: 'Sigorta, katalog, araç filo ve ihale portalları gibi alanlardaki geniş uzmanlığımızla,hızlı çözümler ve kesintisiz destek ile yanınızdayız. İler Otomotiv ile otomotiv yedek parça sektöründe her daim bir adım önde olun!',
    url: '/corvette.jpg',
  },
  {
    id: 3,
    name: 'Bu sektördeki 34 yıllık tecrübemizle, yedek parça piyasasında adımızı sağlam temellere oturtuyoruz. Çıraklıktan tepe yönetimine kadar geçirdiğimiz her aşamada edindiğimiz bilgi ve deneyimle birlikte, müşterilerimize en iyi hizmeti sunma konusundaki kararlılığımızı sürdürüyoruz. Her geçen gün artan bilgi birikimimiz ve sektördeki tecrübemizle, Otomotiv yedek parça piyasasında tanınan, güvenilen, danışılan firmaların başında gelmek ana hedefimizdir.  ',
    url: '/subaru.jpg',
  },
]
export default HeaderSection
