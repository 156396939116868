import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import './navbar.css'

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const closeMenu = () => {
    setMenuOpen(false)
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Eğer tıklanan element mobile-links ya da menu-icon içinde değilse menüyü kapat
      if (
        menuOpen &&
        !event.target.closest('.mobile-links') &&
        !event.target.closest('.menu-icon')
      ) {
        closeMenu()
      }
    }

    // Sayfa üzerinde herhangi bir yere tıklandığında handleOutsideClick fonksiyonunu çağır
    document.addEventListener('click', handleOutsideClick)

    // Component unmount olduğunda event listener'ı temizle
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [menuOpen])

  return (
    <nav className="navbar">
      {/* <div className="logo">
        <img src="./İlerOto_Logo.png" alt="İlerOto Logo" />
      </div>
      <NavLink
        to="/"
        className={({ isActive }) => (isActive ? 'link active' : 'link')}
      >
        <h2 className="logo-header">İLER OTOMOTİV</h2>
      </NavLink> */}
      <div className="logo">
        <NavLink
          to="/"
          className={({ isActive }) => (isActive ? 'link active' : 'link')}
        >
          <a className="navbar-brand">
            <img
              className="logo logo-dark"
              src="logo-dark.png"
              alt="iler otomotiv logo"
            />
          </a>
        </NavLink>
      </div>

      <div className="links">
        <div className="menu-icon" onClick={toggleMenu}>
          ☰
        </div>
        <div className={`mobile-links ${menuOpen ? 'active' : ''}`}>
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? 'link active' : 'link')}
            onClick={closeMenu}
          >
            Anasayfa
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) => (isActive ? 'link active' : 'link')}
            onClick={closeMenu}
          >
            Hakkımızda
          </NavLink>
          <NavLink
            to="/products"
            className={({ isActive }) => (isActive ? 'link active' : 'link')}
            onClick={closeMenu}
          >
            Hizmetlerimiz
          </NavLink>
          <NavLink
            to="/login"
            className={({ isActive }) => (isActive ? 'link active' : 'link')}
            onClick={closeMenu}
          >
            İletişim
          </NavLink>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
