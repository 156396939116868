import React from 'react'
import './footer.css'
import { FaFacebookF, FaInstagram, FaTwitter, FaPhoneAlt } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <>
      <div className="footer-top">
        <footer className="footer footer-1">
          <div className="footer-center">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-5 footer-widget widget-about">
                  <div className="footer-widget-title">
                    <h5>Hakkımızda</h5>
                  </div>
                  <div className="widget-content">
                    <p>
                      İLER OTOMOTİV, sektörde 34 yıllık deneyimle öncü bir
                      konumda. 2023'te kurulan şirket, çeşitli alanlarda çalışan
                      ortakların birikimleriyle hızla büyüdü. Yedek parça
                      listesi analizi, fiyat karşılaştırmaları gibi hizmetler
                      sunuyor.
                    </p>
                    <div className="module module-social">
                      <a className="share-facebook">
                        <FaFacebookF />
                      </a>
                      <a className="share-instagram">
                        <FaInstagram />
                      </a>
                      <a className="share-twitter">
                        <FaTwitter />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-2 offset-lg-1 footer-widget widget-links">
                  <div className="footer-widget-title">
                    <h5>İler Otomotiv</h5>
                  </div>
                  <div className="widget-content">
                    <ul>
                      <li>
                        <NavLink
                          to="/about"
                          className={({ isActive }) =>
                            isActive ? 'link active' : 'link'
                          }
                        >
                          <a href="javascript:void(0)">Hakkımızda</a>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/products"
                          className={({ isActive }) =>
                            isActive ? 'link active' : 'link'
                          }
                        >
                          <a href="javascript:void(0)">Hizmetlerimiz</a>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/login"
                          className={({ isActive }) =>
                            isActive ? 'link active' : 'link'
                          }
                        >
                          <a href="javascript:void(0)">İletişim</a>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="footer-right">
                  <div className="col-sm-6 col-md-6 col-lg-3 footer-widget widget-contact">
                    <div className="footer-widget-title">
                      <h5>Hızlı İletişim</h5>
                    </div>
                    <div className="widget-content">
                      <p>
                        Herhangi bir sorunuz varsa veya yardıma ihtiyacınız
                        varsa, <br /> ekibimizle iletişimegeçmekten çekinmeyin.
                      </p>
                      <ul>
                        <li className="phone">
                          <a href="tel:+90 543 780 35 04">
                            <FaPhoneAlt /> 543 780 35 04
                          </a>
                        </li>
                        <li className="address">
                          <a href="javascript:void(0)">
                            Barbaros Mah.Barbaros Cad. No:52/2 <br /> Ataşehir –
                            İstanbul
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default Footer
